// Generated by Framer (d894e1b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/Trr52nKSH";

const enabledGestures = {bjs5AcdCy: {hover: true}};

const cycleOrder = ["bjs5AcdCy"];

const serializationHash = "framer-O8Wjf"

const variantClassNames = {bjs5AcdCy: "framer-v-1a1o1iw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, uOjrJNCPT: title ?? props.uOjrJNCPT ?? "team"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, uOjrJNCPT, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "bjs5AcdCy", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-O8Wjf", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link {...addPropertyOverrides({"bjs5AcdCy-hover": {href: "/team", openInNewTab: false}}, baseVariant, gestureVariant)}><motion.a {...restProps} className={`${cx("framer-1a1o1iw", className)} framer-5z9jfq`} data-framer-name={"nav-team"} layoutDependency={layoutDependency} layoutId={"bjs5AcdCy"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"bjs5AcdCy-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 className={"framer-styles-preset-6tspdq"} data-styles-preset={"Trr52nKSH"} style={{"--framer-text-alignment": "left"}}>team</motion.h1></React.Fragment>} className={"framer-18m6na5"} data-framer-name={"angebot"} layoutDependency={layoutDependency} layoutId={"I32s4GN7Y"} style={{"--framer-paragraph-spacing": "0px", opacity: 0.25}} text={uOjrJNCPT} variants={{"bjs5AcdCy-hover": {opacity: 1}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-O8Wjf [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-O8Wjf .framer-5z9jfq { display: block; }", ".framer-O8Wjf .framer-1a1o1iw { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 20px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 760px; }", ".framer-O8Wjf .framer-18m6na5 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-O8Wjf .framer-v-1a1o1iw .framer-1a1o1iw { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-O8Wjf .framer-1a1o1iw { gap: 0px; } .framer-O8Wjf .framer-1a1o1iw > * { margin: 0px; margin-bottom: calc(20px / 2); margin-top: calc(20px / 2); } .framer-O8Wjf .framer-1a1o1iw > :first-child { margin-top: 0px; } .framer-O8Wjf .framer-1a1o1iw > :last-child { margin-bottom: 0px; } }", ".framer-O8Wjf.framer-v-1a1o1iw.hover .framer-1a1o1iw { text-decoration: none; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 84
 * @framerIntrinsicWidth 760
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"fIo2t2AvS":{"layout":["fixed","auto"]}}}
 * @framerVariables {"uOjrJNCPT":"title"}
 * @framerImmutableVariables false
 */
const FramerL31csb2ow: React.ComponentType<Props> = withCSS(Component, css, "framer-O8Wjf") as typeof Component;
export default FramerL31csb2ow;

FramerL31csb2ow.displayName = "nav-team";

FramerL31csb2ow.defaultProps = {height: 84, width: 760};

addPropertyControls(FramerL31csb2ow, {uOjrJNCPT: {defaultValue: "team", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerL31csb2ow, [...sharedStyle.fonts])